import axios from "axios";

// Fetch live events
export const fetchLiveEvents = async () => {
  const response = await axios.get(
    "https://www.sofascore.com/api/v1/sport/football/events/live"
  );
  return response.data.events || [];
};

// Fetch statistics for a specific event
export const fetchEventStatistics = async (eventId) => {
  try {
    const response = await axios.get(
      `https://www.sofascore.com/api/v1/event/${eventId}/statistics`
    );
    return response.data.statistics || [];
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.warn(`Statistics not found for event ${eventId}`);
    } else {
      console.error(`Failed to fetch statistics for event ${eventId}`, error);
    }
    throw error;
  }
};

import React from "react";
import { Table } from "antd";

const EventTable = ({ data, loading }) => {
  // Custom sorter with direction
  const customSorter = (key) => (a, b, sortOrder) => {
    const valueA = a[key];
    const valueB = b[key];

    // Handle N/A cases
    const isANotAvailable = valueA === "N/A";
    const isBNotAvailable = valueB === "N/A";

    // If both values are N/A, treat as equal
    if (isANotAvailable && isBNotAvailable) return 0;

    // If only one is N/A, it goes to the bottom
    if (isANotAvailable) return sortOrder === "ascend" ? 1 : -1;
    if (isBNotAvailable) return sortOrder === "ascend" ? -1 : 1;

    // Normal numerical sorting
    return valueA - valueB;
  };

  const columns = [
    {
      title: "Match",
      dataIndex: "match",
      key: "match",
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "Time",
      dataIndex: "matchTime",
      key: "matchTime",
      sorter: (a, b) => a.matchTimeRaw - b.matchTimeRaw,
      defaultSortOrder: "descend",
    },
    {
      title: "xG",
      dataIndex: "expectedGoals",
      key: "expectedGoals",
      sorter: (a, b, sortOrder) => customSorter("expectedGoals")(a, b, sortOrder),
    },
    {
      title: "Big Chances",
      dataIndex: "bigChanceCreated",
      key: "bigChanceCreated",
      sorter: (a, b, sortOrder) => customSorter("bigChanceCreated")(a, b, sortOrder),
    },
    {
      title: "Total Shots",
      dataIndex: "totalShotsOnGoal",
      key: "totalShotsOnGoal",
      sorter: (a, b, sortOrder) => customSorter("totalShotsOnGoal")(a, b, sortOrder),
    },
    {
      title: "Shots on Target",
      dataIndex: "shotsOnGoal",
      key: "shotsOnGoal",
      sorter: (a, b, sortOrder) => customSorter("shotsOnGoal")(a, b, sortOrder),
    },
    {
      title: "Corners",
      dataIndex: "cornerKicks",
      key: "cornerKicks",
      sorter: (a, b, sortOrder) => customSorter("cornerKicks")(a, b, sortOrder),
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      loading={loading}
      pagination={false}
      rowKey={(record) => record.id || record.match}
    />
  );
};

export default EventTable;

import React from "react";
import { Select } from "antd";

const { Option } = Select;

const AutoRefreshSelector = ({ onChange }) => {
  return (
    <Select
      defaultValue={15000}
      style={{ width: 100 }}
      onChange={(value) => onChange(value)}
    >
      <Option value={5000}>5 sec</Option>
      <Option value={15000}>15 sec</Option>
      <Option value={30000}>30 sec</Option>
      <Option value={60000}>1 min</Option>
      <Option value="off">Off</Option>
    </Select>
  );
};

export default AutoRefreshSelector;

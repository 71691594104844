import React, { useState, useEffect, useRef, useCallback } from "react";
import { Typography, Button, Row, Col, Space, Switch, Radio } from "antd";
import AutoRefreshSelector from "./AutoRefreshSelector";
import EventTable from "./EventTable";
import { fetchLiveEvents, fetchEventStatistics } from "./api";

const { Title } = Typography;

// Define filter labels for stats
const FILTER_LABELS = {
    expectedGoals: "xG",
    bigChanceCreated: "Big Chances",
    totalShotsOnGoal: "Total Shots",
    shotsOnGoal: "Shots on Target",
    cornerKicks: "Corners",
};

// Define time filters
const TIME_FILTERS = ["ALL", "1ST", "2ND"];
const FILTER_KEYS = Object.keys(FILTER_LABELS);

const App = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refreshInterval, setRefreshInterval] = useState(15000); // Default: 15 seconds
    const [filters, setFilters] = useState({
        expectedGoals: true,
        bigChanceCreated: true,
        totalShotsOnGoal: true,
        shotsOnGoal: true,
        cornerKicks: true,
    });
    const [selectedTimeFilter, setSelectedTimeFilter] = useState("ALL"); // Default: ALL

    const intervalRef = useRef();

    const roundToTwo = (value) => (value !== undefined ? Math.round(value * 100) / 100 : "N/A");

    const calculateMatchTime = (event) => {
        const matchStatus = event.status?.description?.toLowerCase();
        const currentPeriodStartTimestamp = event.time?.currentPeriodStartTimestamp;
        const currentTimestamp = Date.now() / 1000;
    
        // Handle halftime and full-time cases
        if (matchStatus === "halftime") {
            return { display: "HT", raw: 2700 }; // 45 minutes in seconds
        }
        if (matchStatus === "finished") {
            return { display: "FT", raw: 5400 }; // 90 minutes in seconds
        }
    
        // Handle matches without valid timestamps
        if (!currentPeriodStartTimestamp) {
            return { display: "N/A", raw: 0 }; // Invalid time
        }
    
        // Calculate elapsed time in seconds
        const elapsedSeconds = currentTimestamp - currentPeriodStartTimestamp;
        if (elapsedSeconds < 0) {
            return { display: "N/A", raw: 0 }; // Invalid future timestamps
        }
    
        const elapsedMinutes = Math.ceil(elapsedSeconds / 60);
    
        // Handle 1st and 2nd halves
        if (matchStatus === "1st half") {
            return { display: `${elapsedMinutes}'`, raw: elapsedMinutes * 60 };
        }
        if (matchStatus === "2nd half") {
            return { display: `${45 + elapsedMinutes}'`, raw: (45 + elapsedMinutes) * 60 };
        }
    
        // Handle extra time
        if (matchStatus === "extra time") {
            return { display: `${90 + elapsedMinutes}'`, raw: (90 + elapsedMinutes) * 60 };
        }
    
        return { display: "N/A", raw: 0 }; // Default fallback
    };

    const processStatistics = (statistics, period) => {
        const periodStats = statistics.find((stat) => stat.period === period);
        if (!periodStats) return FILTER_KEYS.reduce((acc, key) => ({ ...acc, [key]: "N/A" }), {});
    
        const extractStat = (key) => {
            const group = periodStats.groups.find((g) =>
                g.statisticsItems.some((item) => item.key === key)
            );
            const stat = group?.statisticsItems.find((item) => item.key === key);
            if (!stat || stat.homeValue + stat.awayValue === 0) return "N/A"; // Если значение 0, возвращаем N/A
            return roundToTwo(stat.homeValue + stat.awayValue);
        };
    
        return FILTER_KEYS.reduce((acc, key) => {
            acc[key] = extractStat(key);
            return acc;
        }, {});
    };

    const applyFilters = useCallback(() => {
        const filtered = data
            .filter((event) => {
                // Фильтруем матчи, где нет статистики для выбранного тайма
                if (selectedTimeFilter !== "ALL" && !event.statistics[selectedTimeFilter]) {
                    return false;
                }
    
                // Проверяем каждый фильтр
                for (const key of FILTER_KEYS) {
                    const stats = event.statistics[selectedTimeFilter] || {};
                    const value = stats[key];
    
                    // Если фильтр включен и значение `N/A`, исключаем матч
                    if (filters[key] && (!value || value === "N/A")) {
                        return false;
                    }
                }
                return true;
            })
            .map((event) => {
                // Подставляем данные статистики для выбранного тайма
                const stats = event.statistics[selectedTimeFilter] || {};
                return {
                    ...event,
                    ...stats,
                };
            });
    
        setFilteredData(
            filtered.sort((a, b) => a.matchTimeRaw - b.matchTimeRaw) // Сортируем по времени
        );
    }, [data, filters, selectedTimeFilter]);

    const updateData = useCallback(async () => {
        setLoading(true);
    
        try {
            const events = await fetchLiveEvents();
    
            const processedData = await Promise.all(
                events.map(async (event) => {
                    if (!event.detailId) {
                        console.warn(
                            `Event skipped: No detailId for ${event.homeTeam.name} vs ${event.awayTeam.name}`
                        );
                        return null;
                    }
    
                    try {
                        const statistics = await fetchEventStatistics(event.id);
                        const { display: matchTime, raw: matchTimeRaw } = calculateMatchTime(event);
    
                        return {
                            match: `${event.homeTeam.name} vs ${event.awayTeam.name}`,
                            score: `${event.homeScore.current} - ${event.awayScore.current}`,
                            matchTime,
                            matchTimeRaw, // Correctly calculated time for sorting
                            statistics: {
                                ALL: processStatistics(statistics, "ALL"),
                                "1ST": processStatistics(statistics, "1ST"),
                                "2ND": processStatistics(statistics, "2ND"),
                            },
                        };
                    } catch (error) {
                        console.error(`Failed to fetch statistics for ${event.homeTeam.name} vs ${event.awayTeam.name}`);
                        return null;
                    }
                })
            );
    
            const newData = processedData.filter((item) => item !== null);
            setData(newData);
        } catch (error) {
            console.error("Failed to fetch events", error);
        } finally {
            setLoading(false);
        }
    }, []);
    

    useEffect(() => {
        if (refreshInterval === "off") {
            clearInterval(intervalRef.current);
        } else {
            updateData();
            intervalRef.current = setInterval(updateData, refreshInterval);
        }

        return () => clearInterval(intervalRef.current);
    }, [refreshInterval, updateData]);

    useEffect(() => {
        applyFilters();
    }, [applyFilters]);

    const handleFilterChange = (key, checked) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [key]: checked,
        }));
    };

    const handleTimeFilterChange = (e) => {
        setSelectedTimeFilter(e.target.value);
    };

    return (
        <div style={{ padding: 10, margin: "0 auto" }}>
            <Title level={2} style={{ textAlign: "center", marginBottom: 24 }}>
                Sofascore Football Stats
            </Title>
    
            {/* Первая строка: Refresh и AutoRefreshSelector */}
            <Row justify="start" align="middle" style={{ marginBottom: 16 }}>
                <Col xs={24} sm={12}>
                    <Space>
                        <Button onClick={updateData} disabled={loading}>
                            Refresh
                        </Button>
                        <AutoRefreshSelector onChange={setRefreshInterval} />
                    </Space>
                </Col>
            </Row>
    
            {/* Вторая строка: Фильтры по таймам (ALL, 1ST, 2ND) */}
            <Row justify="start" align="middle" style={{ marginBottom: 16 }}>
                <Col xs={24} sm={12}>
                    <Radio.Group
                        value={selectedTimeFilter}
                        onChange={handleTimeFilterChange}
                        buttonStyle="solid"
                    >
                        {TIME_FILTERS.map((filter) => (
                            <Radio.Button key={filter} value={filter}>
                                {filter}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                </Col>
            </Row>
    
            {/* Третья строка: Фильтры для статистики */}
            <Row justify="start" style={{ marginBottom: 16 }}>
                <Space wrap>
                    {FILTER_KEYS.map((key) => (
                        <div key={key} style={{ display: "flex", alignItems: "center" }}>
                            <Switch
                                checked={filters[key]}
                                onChange={(checked) => handleFilterChange(key, checked)}
                            />
                            <span style={{ marginLeft: 8 }}>{FILTER_LABELS[key]}</span>
                        </div>
                    ))}
                </Space>
            </Row>
    
            {/* Таблица с данными */}
            <Row>
                <Col xs={24}>
                    <div style={{ overflowX: "auto" }}>
                        <EventTable
                            data={filteredData}
                            loading={loading}
                            enableSorting
                            sortByField="matchTimeRaw"
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
    
};

export default App;
